
a:link {
    color: #FF1616;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #FF1616;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: #FA8582;
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    color: #FA8582;
    background-color: transparent;
    text-decoration: underline;
}
