.header {
    font-size: 3.5vw !important;
    padding-top: 1.5vw;
    font-weight: bold;

}

.bar {
    height: 4vw;
    pointer-events: none;
}
.image3 {
    margin: auto;
    height: 70pt !important;

}

